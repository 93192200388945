import React from 'react';
import instastaffImage from '../assets/images/instastaff.gif';
import photolabsImage from '../assets/images/photolabs.gif';

const Projects = ({ darkMode }) => {

  const projectData = [
    {
      title: "Instastaff",
      description: "An app that facilitates the efficient exchange of labor and work requests by providing the logistic and operational relief to workers and providers in the medical service field.",
      image: instastaffImage,
      tech: ["React", "Tailwind CSS", "Material UI", "Express.js", "Node.js", "PostgreSQL"],
      link: "https://github.com/dialop/instastaff.git",
    },

    {
      title: "Photolabs",
      description: "A client side single-page application that allows users to view photos in a react view-layer library.",
      image: photolabsImage,
      tech: ["React", "SCSS", "Express.js", "PostgreSQL"],
      link: "https://github.com/dialop/photolabs-starter.git",
    },
  ];

  
  return (
    <div className={`py-20 px-4 mt- mt-10 ${darkMode ? 'bg-blue-850' : 'bg-gray-100'}`}> 
      <div className="container mx-auto">
        <h2 className={`text-5xl font-bold py-4 mb-10 text-center ${darkMode ? 'text-white' : 'text-black'}`}>PROJECTS</h2>
        <p className="text-2xl mb-20 text-center text-gray-800 dark:text-gray-500">
          Explore my latest projects
        </p>
        
        {/* Project Grid */}
        <div className="grid grid-cols-1 gap-12">
          {projectData.map((project, index) => (
            <div 
              key={index} 
              className={`bg-transparent text-white shadow-lg rounded-lg overflow-hidden transform transition duration-500 hover:scale-105 
              ${darkMode ? 'bg-blue-270 text-white' : 'bg-transparent text-black'} animate__animated animate__fadeIn`}
              style={{ backgroundColor: '#ffffff08' }} 
            >
              <img src={project.image} alt={project.title} className="w-full h-65vh object-cover" />
              <div className="p-8">
                <h3 className={`text-2xl font-semibold mb-4 ${darkMode ? 'text-white' : 'text-black'} animate__animated animate__slideInUp`}>
                  {project.title}
                </h3>
                <p className={`text-gray-700 mb-5 ${darkMode ? 'text-white' : 'text-black'} animate__animated animate__slideInUp`}>
                  {project.description}
                </p>
                <ul className="flex flex-wrap gap-3 mb-5">
                  {project.tech.map((tech, index) => (
                    <li 
                      key={index} 
                      className={`text-sm font-medium ${darkMode ? 'text-white' : 'text-gray-500'} px-4 py-2 ${darkMode ? 'bg-gray-600' : 'bg-gray-200'} rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-105`}
                    >
                      {tech}
                    </li>
                  ))}
                </ul>
                <a 
                href={project.link} 
                target='_blank'
                rel='noopener noreferrer'
                className={`inline-block px-2 py-2 ${darkMode ? 'bg-blue-500' : 'bg-blue-500'} text-white rounded hover:bg-blue-700 transition-colors`}>
                  Source Code
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
  
  export default Projects;

